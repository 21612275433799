<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Novo Prestador
        </span>
      </h3>
    </div>
    <Form url="/pessoas/create" :prestador="prestador" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PrestadorData from "./PrestadorData";

export default {
  name: "app-prestador-create",
  data() {
    return {
      prestador: new PrestadorData({})
    };
  },
  components: {
    Form
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Novo Prestador"
      }
    ]);
  }
};
</script>
