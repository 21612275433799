import { required } from "vuelidate/lib/validators";

export default class OrdemData {
  numero = null;

  id_veiculo_prestador = null;

  id_veiculo_cliente = null;

  data_sinistro = "";

  descricao_sinistro = "";

  local_sinistro = "";

  atendimento = "";

  id_pessoa = null;

  valor_aceitado = 0;

  valor_pago = 0;

  valor_restante = 0;

  parcelas = null;

  solicitante = null;

  telefone_solicitante = null;

  data_pagamento = null;

  id_motorista = null;

  servicos = null;

  quilometragem = null;

  total_horas = null;

  valor_total_horas = null;

  tipo_os = null;

  status = 1;

  id = null;

  constructor({
    numero = null,
    id_veiculo_prestador = null,
    id_veiculo_cliente = null,
    data_sinistro = "",
    descricao_sinistro = "",
    local_sinistro = "",
    atendimento = "",
    id_pessoa = null,
    valor_aceitado = 0,
    valor_pago = 0,
    valor_restante = 0,
    parcelas = null,
    solicitante = null,
    telefone_solicitante = null,
    data_pagamento = null,
    id_motorista = null,
    servicos = null,
    quilometragem = null,
    total_horas = null,
    valor_total_horas = null,
    tipo_os = null,
    status = 1,
    id = null,
  }) {
    this.numero = numero;
    this.id_veiculo_prestador = id_veiculo_prestador;
    this.id_veiculo_cliente = id_veiculo_cliente;
    this.data_sinistro = data_sinistro;
    this.descricao_sinistro = descricao_sinistro;
    this.local_sinistro = local_sinistro;
    this.atendimento = atendimento;
    this.id_pessoa = id_pessoa;
    this.valor_aceitado = valor_aceitado;
    this.valor_pago = valor_pago;
    this.valor_restante = valor_restante;
    this.parcelas = parcelas;
    this.solicitante = solicitante;
    this.telefone_solicitante = telefone_solicitante;
    this.data_pagamento = data_pagamento;
    this.id_motorista = id_motorista;
    this.servicos = servicos;
    this.quilometragem = quilometragem;
    this.total_horas = total_horas;
    this.valor_total_horas = valor_total_horas;
    this.tipo_os = tipo_os;
    this.status = status;
    this.id = id;
  }
}

export const OrdemDataRequired = {
  data_sinistro: {
    required
  },
  numero: {
    required
  },
};
