import { required } from "vuelidate/lib/validators";

export default class OrcamentoData {
  referencia = null;

  data = null;

  id_pessoa = null;

  equipamentos = null;

  local = null;

  status = 1;

  id = null;

  constructor({
    referencia = null,
    data = null,
    id_pessoa = null,
    equipamentos = null,
    local = null,
    status = 1,
    id = null,
  }) {
    this.referencia = referencia;
    this.data = data;
    this.id_pessoa = id_pessoa;
    this.equipamentos = equipamentos;
    this.local = local;
    this.status = status;
    this.id = id;
  }
}

export const OrcamentoDataRequired = {
  referencia: {
    required
  },
  data: {
    required
  },
};
