import { required } from "vuelidate/lib/validators";

export default class ClienteData {
  nome = "";

  email = "";

  email_adicional = "";

  id_tipo = 1;

  telefone = null;

  identificacao = null;

  cep = null;

  cidade = null;

  numero = null;

  bairro = null;

  uf = null;

  endereco = null;

  rg = null;

  id = null;

  constructor({
    nome = "",
    email = "",
    email_adicional = "",
    id_tipo = 1,
    telefone = null,
    identificacao = null,
    cep = null,
    cidade = null,
    numero = null,
    bairro = null,
    uf = null,
    endereco = null,
    rg = null,
    id = null,
  }) {
    this.nome = nome;
    this.email = email;
    this.email_adicional = email_adicional;
    this.id_tipo = id_tipo;
    this.telefone = telefone;
    this.identificacao = identificacao;
    this.cep = cep;
    this.cidade = cidade;
    this.numero = numero;
    this.bairro = bairro;
    this.uf = uf;
    this.endereco = endereco;
    this.rg = rg;
    this.id = id;
  }
}

export const ClienteDataRequired = {
  nome: {
    required
  },
  email: {
    required
  },
  telefone: {
    required
  },
  identificacao: {
    required
  },
  cep: {
    required
  },
};
