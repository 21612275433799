<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none;">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-row>
            <b-col md="4">
              <b-form-group id="nome" label-for="nome" label="Nome">
                <b-form-input
                  v-model="$v.form.nome.$model"
                  :state="validateState('nome')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="email" label-for="email" label="E-mail">
                <b-form-input
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="telefone"
                label="Telefone"
                label-for="example-input-0"
              >
                <b-form-input
                  v-mask="['(##) #### ####', '(##) # #### ####']"
                  v-model="$v.form.telefone.$model"
                  :state="validateState('telefone')"
                  aria-describedby="input-0-live-feedback"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                id="identificacao"
                label="CPF/CNPJ"
                label-for="identificacao"
              >
                <b-form-input
                  id="identificacao"
                  name="identificacao"
                  v-model="form.identificacao"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  :state="validateState('identificacao')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                id="rg"
                label="IE"
                label-for="IE"
              >
                <b-form-input
                  id="rg"
                  name="rg"
                  v-model="form.rg"
                  v-mask="['###.###.###/####']"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="CEP" label-for="cep">
                <b-form-input
                  ref="cep"
                  id="cep"
                  name="form.cep"
                  v-model="$v.form.cep.$model"
                  :state="validateState('cep')"
                  v-mask="['#####-###']"
                  @change="consultaCep"
                  aria-describedby="form.cep"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Número" label-for="numero">
                <b-form-input
                  id="numero"
                  name="numero"
                  v-model="form.numero"
                  aria-describedby="form.numero"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Bairro" label-for="bairro">
                <b-form-input
                  id="bairro"
                  name="bairro"
                  v-model="form.bairro"
                  aria-describedby="form.bairro"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Logradouro" label-for="endereco">
                <b-form-input
                  id="endereco"
                  name="endereco"
                  v-model="form.endereco"
                  aria-describedby="endereco"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Cidade" label-for="cidade">
                <b-form-input
                  id="cidade"
                  name="cidade"
                  v-model="form.cidade"
                  aria-describedby="form.cidade"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="UF" label-for="uf">
                <b-form-input
                  id="uf"
                  name="uf"
                  v-model="form.uf"
                  aria-describedby="form.uf"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_prestador_novo"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <button
                v-on:click="
                  () => {
                    $router.push({ name: 'prestadores' });
                  }
                "
                class="btn btn-clean font-weight-bold"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import PrestadorData, { PrestadorDataRequired } from "./PrestadorData";
import { mask } from "vue-the-mask";

export default {
  name: "app-prestador-form",
  props: {
    url: {
      type: String,
    },
    prestador: {
      type: PrestadorData,
      required: true,
    },
  },
  directives: {
    mask,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
    };
  },
  validations: {
    form: PrestadorDataRequired,
  },
  mounted() {
    this.getDataInit();
  },
  watch: {
    prestador(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new PrestadorData({});

      if (this.prestador.id) {
        this.form = await this.prestador;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async consultaCep() {
      this.setLoading(true);
      const cep = await this.consultaCEP(this.form.cep);
      this.form.endereco = cep.data.street;
      this.form.bairro = cep.data.district;
      this.form.cidade = cep.data.city;
      this.form.uf = cep.data.uf;
      this.setLoading(false);
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_prestador_novo"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");
        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "prestadores" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
