import { required } from "vuelidate/lib/validators";

export default class UsuarioData {
  nome = "";

  email = "";

  tipo = null;

  password = null;

  id = null;

  constructor({
    nome = "",
    email = "",
    tipo = null,
    password = null,
    id = null,
  }) {
    this.nome = nome;
    this.email = email;
    this.tipo = tipo;
    this.password = password;
    this.id = id;
  }
}

export const UsuarioDataRequired = {
  nome: {
    required
  },
  email: {
    required
  },
  tipo: {
    required
  },
  password: {
    required
  },
};
