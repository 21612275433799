<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-row>
            <b-col md="4">
              <b-form-group label="Qual tipo do arquivo?" label-for="tipo">
                <b-form-select v-model="form.tipo">
                  <b-form-select-option
                    v-for="(t, index) in tiposArquivos"
                    :value="t.id"
                    :key="'tipo_arquivo' + index"
                  >
                    {{ t.descricao }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="nome" label-for="nome" label="Nome">
                <b-form-input
                  v-model="$v.form.nome.$model"
                  :state="validateState('nome')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="file" label-for="file" label="Arquivo">
                <b-form-file
                  @change="setArquivo"
                  placeholder="Clique para o arquivo"
                  drop-placeholder="Clique para o arquivo"
                  accept="*"
                  capture
                ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_prestador_novo"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <button
                v-on:click="
                  () => {
                    $router.push({ name: 'arquivos' });
                  }
                "
                class="btn btn-clean font-weight-bold"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import ArquivoData, { ArquivoDataRequired } from "./ArquivoData";
import "vue-select/dist/vue-select.css";
import { mask } from "vue-the-mask";

export default {
  name: "app-arquivo-form",
  props: {
    url: {
      type: String,
    },
    arquivo: {
      type: ArquivoData,
      required: true,
    },
  },
  directives: {
    mask,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tiposArquivos: [
        {
          id: 1,
          descricao: "Leilão",
        },
        {
          id: 2,
          descricao: "Normal",
        },
      ],
      tabIndex: 0,
      form: {},
      arquivoFile: null,
    };
  },
  validations: {
    form: ArquivoDataRequired,
  },
  async mounted() {
    await this.getDataInit();
  },
  watch: {
    arquivo(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new ArquivoData({});

      if (this.arquivo.id) {
        this.form = await this.arquivo;
      }
    },
    async setArquivo(event) {
      this.arquivoFile = await event.target.files[0];
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_prestador_novo"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        let fd = new FormData();

        if (this.arquivoFile != null) {
          fd.append("file", this.arquivoFile);
        }

        if (this.form.id != null) {
          fd.append("id", this.form.id);
        }

        fd.append("nome", this.form.nome);
        fd.append("tipo", this.form.tipo);

        await this.$http.post(this.url, fd);

        this.$router.push({ name: "arquivos" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
