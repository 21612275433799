import { required } from "vuelidate/lib/validators";

export default class VeiculoData {
  placa = "";

  modelo = "";

  descricao = "";

  cor = null;

  id_pessoa = null;

  ativo = true;

  id = null;

  constructor({
    placa = "",
    modelo = "",
    descricao = "",
    cor = null,
    id_pessoa = null,
    ativo = true,
    id = null,
  }) {
    this.placa = placa;
    this.modelo = modelo;
    this.descricao = descricao;
    this.cor = cor;
    this.id_pessoa = id_pessoa;
    this.ativo = ativo;
    this.id = id;
  }
}

export const VeiculoDataRequired = {
  placa: {
    required
  },
  descricao: {
    required
  },
};
