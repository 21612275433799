import orcamentos from "@/modules/Orcamentos/pages/Index";
import orcamentoNovo from "@/modules/Orcamentos/pages/Create";
import orcamentoEditar from "@/modules/Orcamentos/pages/Update";

export default [
  {
    path: "/orcamentos",
    name: "orcamentos",
    component: orcamentos
  },
  {
    name: "orcamentoNovo",
    path: "/orcamentos/novo",
    component: orcamentoNovo
  },
  {
    name: "orcamentoEditar",
    path: "/orcamentos/:id/editar",
    component: orcamentoEditar
  }
];
