import veiculos from "@/modules/Veiculos/pages/Index";
import veiculoNovo from "@/modules/Veiculos/pages/Create";
import veiculoEditar from "@/modules/Veiculos/pages/Update";

export default [
  {
    path: "/veiculos",
    name: "veiculos",
    component: veiculos
  },
  {
    name: "veiculoNovo",
    path: "/veiculo/novo",
    component: veiculoNovo
  },
  {
    name: "veiculoEditar",
    path: "/veiculo/:id/editar",
    component: veiculoEditar
  }
];
