/* eslint-disable */

import Vue from "vue";
import Router from "vue-router";
import Login from '@/modules/Login';
import Dashboard from '@/modules/Dashboard';
import Usuarios from '@/modules/Usuarios';
import Clientes from '@/modules/Clientes';
import Prestadores from '@/modules/Prestadores';
import Veiculos from '@/modules/Veiculos';
import Ordens from '@/modules/Ordens';
import Orcamentos from '@/modules/Orcamentos';
import Relatorios from '@/modules/Relatorios';
import Arquivos from '@/modules/Arquivos';
import Layout from '@/view/layout/Layout';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: Layout,
      children: [
        ...Dashboard,
        ...Usuarios,
        ...Clientes,
        ...Prestadores,
        ...Veiculos,
        ...Ordens,
        ...Orcamentos,
        ...Relatorios,
        ...Arquivos
      ],
    },
    ...Login,
    {
      path: "/*",
      name: "404",
      component: () => import("@/modules/Errors/pages/404.vue")
    }
  ]
});
