<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none;">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Informações do Orçamento
            </a>
          </li>
        </ul>
      </div>
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <div class="card-body">
            <b-row>
              <b-col md="3">
                <b-form-group
                  id="referencia"
                  label-for="referencia"
                  label="Referência Orçamento"
                >
                  <b-form-input
                    type="text"
                    v-mask="['###-####']"
                    v-model="$v.form.referencia.$model"
                    :state="validateState('referencia')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="data"
                  label-for="data"
                  label="Data do Orçamento"
                >
                  <b-form-input
                    type="date"
                    v-model="$v.form.data.$model"
                    :state="validateState('data')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group label="Contratante" label-for="id_pessoa">
                  <v-select
                    label="nome"
                    @input="setCliente"
                    v-model="form.cliente"
                    :options="clientes"
                  >
                    <template slot="no-options">
                      Informe o nome para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome + " - " + option.telefone }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome + " - " + option.telefone }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8">
                <b-form-group
                  id="descricao"
                  label-for="descricao"
                  label="Equipamento"
                >
                  <b-form-input type="text" v-model="descricao"> </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="investimento"
                  label-for="investimento"
                  label="Investimento"
                >
                  <money
                    class="form-control"
                    v-model="investimento"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  id="descricaoInvestimento"
                  label-for="descricaoInvestimento"
                  label="Descrição do Investimento"
                >
                  <b-form-textarea
                    type="text"
                    placeholder="Descrever sobre o investimento......."
                    v-model="descricaoInvestimento"
                    rows="3"
                    max-rows="6"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <button
                  style="margin-top: 20px"
                  v-on:click="addEquipamento()"
                  class="btn btn-primary btn-small font-weight-bold"
                >
                  Adicionar
                </button>
              </b-col>
            </b-row>
            <b-row>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Investimento</th>
                    <th scope="col">Descrição investimento</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody v-for="(item, index) in equipamentos" :key="index">
                  <tr>
                    <th>{{ index }}</th>
                    <th>{{ item.descricao }}</th>
                    <th style="font-size: 10px">{{ item.descricaoInvestimento }}</th>
                    <td>{{ valorReal(item.investimento) }}</td>
                    <td>
                      <button
                        @click="excluir(index)"
                        class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                        title="Excluir"
                      >
                        <i class="la la-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group
                  id="local"
                  label-for="local"
                  label="Local e data"
                >
                  <b-form-textarea
                    type="text"
                    placeholder="Descrever sobre o local"
                    v-model="local"
                    rows="3"
                    max-rows="4"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group label="Defina a situação?" label-for="status">
                  <b-form-select v-model="form.status">
                    <b-form-select-option
                      v-for="(t, index) in tipoSituacao"
                      :value="t.id"
                      :key="'tipo_situacao' + index"
                    >
                      {{ t.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button @click="gravar()" class="btn btn-primary font-weight-bold">
              Salvar
            </button>
            <button
              v-on:click="
                () => {
                  $router.push({ name: 'orcamentos' });
                }
              "
              class="btn btn-clean font-weight-bold"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import OrcamentoData, { OrcamentoDataRequired } from "./OrcamentoData";
import { mask } from "vue-the-mask";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";

export default {
  name: "app-orcamento-form",
  props: {
    url: {
      type: String,
    },
    orcamento: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    "v-select": VueSelect,
    Money,
  },
  directives: {
    mask,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      clientes: [],
      equipamentos: [],
      descricao: null,
      descricaoInvestimento: null,
      investimento: 0.0,
      local: null,
      tipoSituacao: [
        {
          id: 1,
          descricao: "Enviado",
        },
        {
          id: 2,
          descricao: "Reprovado",
        },
        {
          id: 3,
          descricao: "Aprovado",
        },
      ],
      configMoney: settingMoney,
    };
  },
  validations: {
    form: OrcamentoDataRequired,
  },
  async mounted() {
    await this.getDataInit();
  },
  watch: {
    orcamento(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.local = newValue.local;
        this.equipamentos = newValue.equipamentos;
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new OrcamentoData({});
      this.clientes = await this.getCliente();

      if (this.orcamento.id) {
        this.form = await this.orcamento;
        this.setCliente(await this.orcamento.cliente);
      }
    },
    setCliente(cliente) {
      this.form.id_pessoa = cliente.id;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    async gravar() {
      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        if (!this.form.id_pessoa) {
          this.$toast.warning("Selecione um contratante!");
          return;
        }

        if (this.equipamentos != null) {
          this.form.equipamentos = this.equipamentos;
        }

        if (this.form.cliente != null) {
          this.form.id_pessoa = this.form.cliente.id;
        }

        if (this.local != null) {
          this.form.local = this.local;
        }

        const user = await JSON.parse(
          window.localStorage.getItem("dados_user")
        );
        this.form.id_atendente = user.id;

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "orcamentos" });
      } catch (error) {
        console.log(error);
      }
    },

    addEquipamento() {
      var equipamento = {};
      equipamento.descricao = this.descricao;
      equipamento.investimento = this.investimento;
      equipamento.descricaoInvestimento = this.descricaoInvestimento;
      this.equipamentos.push(equipamento);
      this.limpaEquipamento();
      this.$toast.success("Equipamento adicionado com sucesso!");
    },

    limpaEquipamento() {
      this.descricao = null;
      this.investimento = 0.0;
      this.descricaoInvestimento = null;
    },
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir equipamento listado ?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.equipamentos.splice(data, 1);
            this.$toast.success("Equipamento excluído!");
          }
        });
    },
  },
  computed: {
    // totalEquipamentos() {
    //   return this.kmChegada - this.kmSaida;
    // },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
