<template>
  <TableApp
    titulo="Orçamentos"
    acaoBtn="/#/orcamentos/novo"
    textoBtn="Novo"
    api-url="/orcamentos/filter"
    :fields="fields"
    classFilterColumn="id"
    ref="orcamentos"
    :perPage="10"
  >
    <template slot="customRow" scope="props">
      <span slot="id" v-if="props.field == 'id'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.id
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="referencia" v-if="props.field == 'referencia'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.referencia
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="data" v-if="props.field == 'data'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                formatDate(props.rowData.data)
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="cliente" v-if="props.field == 'cliente'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                  props.rowData.cliente
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="status" v-if="props.field == 'status'">
        <span
          v-if="props.rowData.status === 1"
          class="label label-lg label-inline label-light-warning"
          >Enviado</span
        >
        <span v-else-if="props.rowData.status === 2" class="label label-lg label-inline label-light-danger"
          >Reprovado</span
        >
        <span v-else-if="props.rowData.status === 3" class="label label-lg label-inline label-light-success"
          >Aprovado</span
        >
      </span>
      <span v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <router-link
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Editar"
                :to="{
                  name: 'orcamentoEditar',
                  params: {
                    id: props.rowData.id
                  }
                }"
              >
              <i class="la la-pencil"></i>
              </router-link>
              <button
                @click="imprimir(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Imprimir"
              >
                <i class="la la-print"></i>
              </button>
              <button
                @click="email(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Enviar e-mail"
              >
                <i class="la la-send-o"></i>
              </button>
              <button
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Excluir"
              >
                <i class="la la-trash"></i>
              </button>
            </div>
          </span>
        </div>
      </span>
      </template>
  </TableApp>
</template>

<script>
import TableApp from "@/components/Table/Table2";
import { mapGetters } from "vuex";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "orcamentos",
  components: {
    TableApp,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      fields: [
        {
          label: "N° Orçamento",
          field: "id",
          type: "text",
          sortable: true,
          slot: true,
          width: "5%",
          filterOptions: {
            enabled: true,
            placeholder: "N° Orçamento"
          }
        },
        {
          label: "Referência",
          field: "referencia",
          type: "text",
          sortable: true,
          slot: true,
          width: "5%",
          filterOptions: {
            enabled: true,
            placeholder: "Referência"
          }
        },
        {
          label: "Data",
          field: "data",
          type: "text",
          sortable: true,
          slot: true,
          width: "5%",
          filterOptions: {
            enabled: true,
            placeholder: "Data"
          }
        },
        {
          label: "Contratante",
          field: "cliente",
          type: "text",
          sortable: true,
          slot: true,
          width: "5%",
          filterOptions: {
            enabled: true,
            placeholder: "Contratante"
          }
        },
        {
          label: "Situação",
          field: "status",
          type: "text",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false
          }
        }
      ]
    };
  },
  methods: {
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir a Orçamento " + data.id + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60"
        })
        .then(async result => {
          if (result.isConfirmed) {
            await this.$http.post("/orcamentos/delete", {
              id: data.id
            });

            this.$refs.orcamentos.refresh();
            this.$toast.success("Orçamento excluído!");
          }
        });
    },
    imprimir(data) {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/orcamentos/imprimir", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Orçamento impresso com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    email(data) {
      swal({
        title: "Deseja enviar para o e-mail: " + data.email,
        text: "Aguarde, pois o e-mail será enviado automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/orcamentos/enviarEmail", {
            id: data.id,
            email: data.email,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("E-mail enviado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    }
  },
    computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
  },
};
</script>
