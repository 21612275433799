import clientes from "@/modules/Clientes/pages/Index";
import clienteNovo from "@/modules/Clientes/pages/Create";
import clienteEditar from "@/modules/Clientes/pages/Update";

export default [
  {
    path: "/clientes",
    name: "clientes",
    component: clientes
  },
  {
    name: "clienteNovo",
    path: "/cliente/novo",
    component: clienteNovo
  },
  {
    name: "clienteEditar",
    path: "/cliente/:id/editar",
    component: clienteEditar
  }
];
