<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Novo Veículo
        </span>
      </h3>
    </div>
    <Form url="/veiculos/create" :veiculo="veiculo" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VeiculoData from "./VeiculoData";

export default {
  name: "app-veiculo-create",
  data() {
    return {
      veiculo: new VeiculoData({})
    };
  },
  components: {
    Form
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Novo Veículo"
      }
    ]);
  }
};
</script>
