<template>
  <TableApp
    titulo="Ordens de Serviço"
    acaoBtn="/#/ordem/novo"
    textoBtn="Novo"
    api-url="/ordens/filter"
    :fields="fields"
    classFilterColumn="numero"
    ref="ordens"
    :perPage="10"
  >
    <template slot="customRow" scope="props">
      <span slot="numero" v-if="props.field == 'numero'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.numero
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="tipo_os" v-if="props.field == 'tipo_os'">
        <span
          v-if="props.rowData.tipo_os == 1"
          class="label label-lg label-inline label-light-success"
          >Guincho</span
        >
        <span
          v-else-if="props.rowData.tipo_os == 2"
          class="label label-lg label-inline label-light-info"
          >Guindaste</span
        >
      </span>
      <span slot="valor_total_horas" v-if="props.field == 'valor_total_horas'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                valorReal(props.rowData.valor_total_horas)
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="data_sinistro" v-if="props.field == 'data_sinistro'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                formatDate(props.rowData.data_sinistro)
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="cliente" v-if="props.field == 'cliente'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.cliente
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="local_sinistro" v-if="props.field == 'local_sinistro'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.local_sinistro
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="operadorNome" v-if="props.field == 'operadorNome'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.operadorNome
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="foto_os" v-if="props.field == 'foto_os'">
        <span class="pl-0 pt-8" v-if="props.rowData.foto_os">
          <div class="d-flex align-items-center">
            <div>
              <a
                :href="
                  'https://broetto.sfo3.digitaloceanspaces.com/os_original/' +
                  props.rowData.foto_os
                "
                target="_blank"
                class="font-weight-bold d-block"
                >Acessar</a
              >
            </div>
          </div>
        </span>
        <span class="pl-0 pt-8" v-if="!props.rowData.foto_os">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">Sem foto</span>
            </div>
          </div>
        </span>
      </span>
      <span v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <router-link
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Editar"
                :to="{
                  name: 'ordemEditar',
                  params: {
                    id: props.rowData.id,
                  },
                }"
              >
                <i class="la la-pencil"></i>
              </router-link>
              <button
                @click="imprimir(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Imprimir"
              >
                <i class="la la-print"></i>
              </button>
              <button
                @click="email(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Enviar e-mail"
              >
                <i class="la la-send-o"></i>
              </button>
              <button
                @click="uparFoto(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Upar Foto"
              >
                <i class="la la-arrow-up"></i>
              </button>
              <button
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Excluir"
              >
                <i class="la la-trash"></i>
              </button>
              <button
                @click="baixarOs(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Baixar OS"
              >
                <i class="la la-money"></i>
              </button>
            </div>
          </span>
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import TableApp from "@/components/Table/Table2";
import { mapGetters } from "vuex";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import Swal from "sweetalert2";

export default {
  name: "ordens",
  components: {
    TableApp,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      fields: [
        {
          label: "N° OS",
          field: "numero",
          type: "text",
          sortable: true,
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: true,
            placeholder: "N° OS",
          },
        },
        {
          label: "Tipo OS",
          field: "tipo_os",
          type: "text",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "Valor",
          field: "valor_total_horas",
          type: "text",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "Data Sinistro",
          field: "data_sinistro",
          type: "text",
          sortable: true,
          slot: true,
          width: "8%",
          filterOptions: {
            enabled: true,
            placeholder: "Data",
          },
        },
        {
          label: "Cliente",
          field: "cliente",
          type: "text",
          sortable: true,
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: true,
            placeholder: "Cliente",
          },
        },
        {
          label: "Local Atendimento",
          field: "local_sinistro",
          type: "text",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Operador",
          field: "operadorNome",
          type: "text",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "Cópia",
          field: "foto_os",
          type: "text",
          sortable: false,
          slot: true,
          width: "2%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir a OS " + data.id + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/ordens/delete", {
              id: data.id,
            });

            this.$refs.ordens.refresh();
            this.$toast.success("Ordem excluída!");
          }
        });
    },
    imprimir(data) {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/ordens/imprimir", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("OS impressa com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    email(data) {
      swal({
        title: "Deseja enviar para o e-mail: " + data.email,
        text: "Aguarde, pois o e-mail será enviado automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/ordens/enviarEmail", {
            id: data.id,
            email: data.email,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("E-mail enviado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },

    async baixarOs(data) {
      let date = await Swal.fire({
        title: "Informe a data da baixa para OS: " + data.numero,
        html: '<input id="datetimepicker" class="form-control" autofocus type="date">',
        preConfirm: () => [document.getElementById("datetimepicker").value],
      });
      if (date.value) {
        let fd = new FormData();
        fd.append("id", data.id);
        fd.append("data_pagamento", date.value[0]);

        this.$http.post("/ordens/baixar-os", fd);
        this.$refs.ordens.refresh();
        this.$toast.success("Ordem atualizada com sucesso!");
      }
    },
    async uparFoto(data) {
      const { value: file } = await Swal.fire({
        title: "Seleciona a imagem",
        input: "file",
        inputAttributes: {
          accept: "pdf/*",
          "aria-label": "Selecione",
        },
      });

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          Swal.fire({
            title: "Imagem enviada com sucesso",
          });
        };
        reader.readAsDataURL(file);

        let fd = new FormData();
        fd.append("id", data.id);
        fd.append("foto_os", file);

        await this.$http.post("/ordens/uparOS", fd);
        this.$refs.ordens.refresh();
        this.$toast.success("Imagem enviada com sucesso!");
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {},
};
</script>
