<template>
  <div>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório Geral:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDateGeral"
                    label-for="toDate"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDateGeral">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDateGeral"
                    label-for="fromDateGeral"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDateGeral">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarGeral()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório de OS pagas:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDatePago"
                    label-for="toDatePago"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDatePago">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDatePago"
                    label-for="fromDatePago"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDatePago">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarPago()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório de OS pendentes:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDatePendente"
                    label-for="toDatePendente"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDatePendente">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDatePendente"
                    label-for="fromDatePendente"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDatePendente">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarPendentes()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório por Cliente:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDate"
                    label-for="toDate"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDate"> </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDate"
                    label-for="fromDate"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDate">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-col md="12">
                    <b-form-group label="Cliente" label-for="id_pessoa">
                      <v-select
                        label="nome"
                        @input="setCliente"
                        v-model="cliente"
                        :options="clientes"
                      >
                        <template slot="no-options">
                          Informe o nome para buscar
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.nome + " - " + option.telefone }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome + " - " + option.telefone }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <button
                @click="gerarPorCliente()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório por Operador:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDateOperador"
                    label-for="toDateOperador"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDateOperador">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDateOperador"
                    label-for="fromDateOperador"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDateOperador">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-col md="12">
                    <b-form-group label="Operador" label-for="id_pessoa">
                      <v-select
                        label="nome"
                        @input="setOperador"
                        v-model="operador"
                        :options="operadores"
                      >
                        <template slot="no-options">
                          Informe o nome para buscar
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <button
                @click="gerarPorOperador()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório por Prestadora:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDatePrestador"
                    label-for="toDatePrestador"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDatePrestador">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDatePrestador"
                    label-for="fromDatePrestador"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDatePrestador">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-col md="12">
                    <b-form-group label="Prestador" label-for="id_pessoa">
                      <v-select
                        label="nome"
                        @input="setPrestador"
                        v-model="prestadora"
                        :options="prestadoras"
                      >
                        <template slot="no-options">
                          Informe o nome para buscar
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <button
                @click="gerarPorPrestador()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório por Veículo Prestador:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDateVeiculo"
                    label-for="toDateVeiculo"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDateVeiculo">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDateVeiculo"
                    label-for="fromDateVeiculo"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDateVeiculo">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-col md="12">
                    <b-form-group label="Veículo" label-for="id_veiculo">
                      <v-select
                        label="descricao"
                        @input="setVeiculo"
                        v-model="veiculo"
                        :options="veiculosPrestadores"
                      >
                        <template slot="no-options">
                          Informe a descrição para buscar
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.descricao }} - {{ option.placa }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.descricao }} - {{ option.placa }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <button
                @click="gerarVeiculoPrestador()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório OS pendentes por Cliente:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDatePendenteCliente"
                    label-for="toDatePendenteCliente"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDatePendenteCliente">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDatePendenteCliente"
                    label-for="fromDatePendenteCliente"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDatePendenteCliente">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-col md="12">
                    <b-form-group label="Cliente" label-for="id_pessoa">
                      <v-select
                        label="nome"
                        @input="setCliente"
                        v-model="cliente"
                        :options="clientes"
                      >
                        <template slot="no-options">
                          Informe o nome para buscar
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <button
                @click="gerarPendenteCliente()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Relatório Medição por Cliente:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="toDateMedicaoCliente"
                    label-for="toDateMedicaoCliente"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="toDateMedicaoCliente">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="fromDateMedicaoCliente"
                    label-for="fromDateMedicaoCliente"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="fromDateMedicaoCliente">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-col md="12">
                    <b-form-group label="Cliente" label-for="id_pessoa">
                      <v-select
                        label="nome"
                        @input="setCliente"
                        v-model="cliente"
                        :options="clientes"
                      >
                        <template slot="no-options">
                          Informe o nome para buscar
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <button
                @click="gerarMedicaoCliente()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import swal from "sweetalert";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "relatorios",
  mixins: [SistemaMixin],
  components: {
    "v-select": VueSelect,
  },
  data() {
    return {
      atendentes: [],
      clientes: [],
      usuarios: [],
      prestadoras: [],
      operadores: [],
      veiculosPrestadores: [],
      fromDate: null,
      toDate: null,
      fromDatePago: null,
      toDatePago: null,
      fromDatePendente: null,
      toDatePendente: null,
      fromDateGeral: null,
      toDateGeral: null,
      fromDateAtendente: null,
      toDateAtendente: null,
      fromDatePrestador: null,
      toDatePrestador: null,
      fromDateOperador: null,
      toDateOperador: null,
      fromDateVeiculo: null,
      toDateVeiculo: null,
      fromDatePendenteCliente: null,
      toDatePendenteCliente: null,
      toDateMedicaoCliente: null,
      fromDateMedicaoCliente: null,
      cliente: [],
      atendente: [],
      prestadora: [],
      operador: [],
      veiculo: [],
    };
  },
  methods: {
    async getDataInit() {
      this.clientes = await this.getCliente();
      this.atendentes = await this.getAtendentes();
      this.prestadoras = await this.getPrestadores();
      this.operadores = await this.getOperadores();
      this.veiculosPrestadores = await this.getVeiculosPrestadores();
    },
    gerarGeral() {
      if (!this.fromDateGeral || !this.toDateGeral) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/geral", {
            fromDate: this.fromDateGeral,
            toDate: this.toDateGeral,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarPago() {
      if (!this.fromDatePago || !this.toDatePago) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/pagas", {
            fromDate: this.fromDatePago,
            toDate: this.toDatePago,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarPendentes() {
      if (!this.fromDatePendente || !this.fromDatePendente) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/pendentes", {
            fromDate: this.fromDatePendente,
            toDate: this.toDatePendente,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarPorCliente() {
      if (!this.cliente) {
        this.$toast.warning("Selecione um cliente para gerar o relatório!");
        return;
      }

      if (!this.fromDate || !this.toDate) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }

      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/cliente", {
            fromDate: this.fromDate,
            toDate: this.toDate,
            cliente: this.cliente,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarPorAtendente() {
      if (!this.atendente) {
        this.$toast.warning("Selecione um atendente para gerar o relatório!");
        return;
      }

      if (!this.fromDateAtendente || !this.toDateAtendente) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }

      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/atendente", {
            fromDate: this.fromDateAtendente,
            toDate: this.toDateAtendente,
            usuario: this.atendente,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarPorOperador() {
      if (!this.operador) {
        this.$toast.warning("Selecione um operador para gerar o relatório!");
        return;
      }

      if (!this.fromDateOperador || !this.toDateOperador) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }

      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/operadores", {
            fromDate: this.fromDateOperador,
            toDate: this.toDateOperador,
            operador: this.operador,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarPorPrestador() {
      if (!this.prestadora) {
        this.$toast.warning("Selecione um prestador para gerar o relatório!");
        return;
      }

      if (!this.fromDatePrestador || !this.toDatePrestador) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }

      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/prestadora", {
            fromDate: this.fromDatePrestador,
            toDate: this.toDatePrestador,
            prestadora: this.prestadora,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarVeiculoPrestador() {
      if (!this.veiculo) {
        this.$toast.warning("Selecione um veículo para gerar o relatório!");
        return;
      }

      if (!this.fromDateVeiculo || !this.toDateVeiculo) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }

      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/veiculos", {
            fromDate: this.fromDateVeiculo,
            toDate: this.toDateVeiculo,
            veiculo: this.veiculo,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarMedicaoCliente() {
      if (!this.cliente) {
        this.$toast.warning("Selecione um cliente para gerar o relatório!");
        return;
      }

      if (!this.fromDateMedicaoCliente || !this.toDateMedicaoCliente) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }

      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/medicao", {
            fromDate: this.fromDateMedicaoCliente,
            toDate: this.toDateMedicaoCliente,
            cliente: this.cliente,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarPendenteCliente() {
      if (!this.cliente) {
        this.$toast.warning("Selecione um cliente para gerar o relatório!");
        return;
      }

      if (!this.fromDatePendenteCliente || !this.toDatePendenteCliente) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }

      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/pendentes-cliente", {
            fromDate: this.fromDatePendenteCliente,
            toDate: this.toDatePendenteCliente,
            cliente: this.cliente,
          })
          .then((result) => {
            if (result["data"]["data"]) {
              window.open(result["data"]["data"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Relatório gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    setCliente(cliente) {
      this.cliente = cliente;
    },
    setAtendente(atendente) {
      this.atendente = atendente;
    },
    setPrestador(prestadora) {
      this.prestadora = prestadora;
    },
    setOperador(operador) {
      this.operador = operador;
    },
    setVeiculo(veiculo) {
      this.veiculo = veiculo;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    await this.getDataInit();
  },
};
</script>
