<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Pet
        </span>
      </h3>
    </div>
    <Form url="/usuarios/update" :usuario="usuario" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import UsuarioData from './UsuarioData';

export default {
  name: "app-usuario-update",
  data() {
    return {
      usuario: new UsuarioData({})
    };
  },
  components: {
    Form
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getUsuario(id) {
      const { data } = await this.$http.post("/usuarios/find", {
        id: id
      });
      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;
        this.setLoading(true);

        const usuario = await this.getUsuario(id);

        this.setLoading(false);

        if (!usuario.id || usuario == null) {
          this.$router.push({ name: "usuarios" });

          return this.$toast.error("Usuário não encontrado!");
        }

        this.usuario = new UsuarioData(usuario);
      } catch (e) {
        return;
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Editando Usuário"
      }
    ]);
    this.getInit();
  }
};
</script>
