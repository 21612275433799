<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-5" v-if="titulo != null">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            titulo
          }}</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            subTitulo
          }}</span>
        </h3>
        <div class="card-toolbar">
          <a
            v-if="acaoBtn != null"
            :href="acaoBtn"
            class="btn btn-success font-weight-bolder font-size-sm"
            >{{ textoBtn }}</a
          >
        </div>
      </div>
      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <div class="table-responsive">
            <vue-good-table
              mode="remote"
              theme="polar-bear"
              styleClass="vgt-table table-striped"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-row-click="clickTd"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :pagination-options="{
                mode: 'pages',
                enabled: true,
                nextLabel: 'Próximo',
                prevLabel: 'Voltar',
                rowsPerPageLabel: '',
                ofLabel: 'de',
                allLabel: 'Todos',
                dropdownAllowAll: false,
                infoFn: params => ''
              }"
              :rows="data"
              :columns="fields"
            >
              <template slot="loadingContent">
                <Loading></Loading>
              </template>
              <template slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">
                  Nenhum registro encontrado
                </div>
              </template>
              <template slot="table-row" slot-scope="props">
                {{ props.slot }}
                <template v-if="props.column.slot">
                  <slot
                    name="customRow"
                    :rowData="props.row"
                    :field="props.column.field"
                  ></slot>
                </template>
                <template v-else>
                  {{ props.formattedRow[props.column.field] }}
                </template>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/Loading";
import _ from "lodash";

export default {
  name: "table-app",
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      data: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: ""
        },
        page: 1,
        per_page: 10
      }
    };
  },
  props: {
    titulo: {
      type: String,
      default: null
    },
    subTitulo: {
      type: String,
      default: null
    },
    acaoBtn: {
      type: String,
      default: null
    },
    textoBtn: {
      type: String,
      default: null
    },
    apiUrl: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  },
  components: {
    VueGoodTable,
    Loading
  },
  methods: {
    ...mapActions(["setLoading"]),
    serach: _.debounce(vm => {
      vm.setLoading(true);
      vm.$http
        .post(vm.apiUrl, vm.serverParams)
        .then(function(response) {
          vm.data = response.data.data;
          vm.setLoading(false);
          (vm.totalRecords = response.data.total),
            (vm.serverParams.page = response.data.current_page);
        })
        .catch(() => {
          vm.setLoading(false);
        });
    }, 350),
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    clickTd(data) {
      console.log(data);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      if (!params[0]) {
        return;
      }

      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      this.serach(this);
    },
    refresh() {
      this.loadItems(this);
    }
  },
  mounted() {
    this.loadItems();
  }
};
</script>

<style>
.vgt-table.bordered th {
  border: 0 !important;
  background-color: #f3f6f9 !important;
  border-bottom: 0 !important;
  letter-spacing: 1px !important;
  font-weight: 600;
  color: #b5b5c3 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.vgt-wrap__footer {
  background-color: #f3f6f9 !important;
  color: #b5b5c3 !important;
  border: 0 !important;
}

.vgt-table th.sortable button:before {
  border-top: 5px solid #7e8299;
}

.vgt-table th.sortable button:after {
  border-bottom: 5px solid #7e8299;
}

table.vgt-table td {
  padding: 0.75rem !important;
  border-bottom: 1px solid #fff !important;
  color: #3f4254 !important;
  vertical-align: middle !important;
  font-weight: 600 !important;
}

table.vgt-table {
  border: 0 !important;
}

.vgt-table.polar-bear thead th {
  color: #b5b5c3;
  font-weight: 600;
  border-bottom: 1px solid #e3e8ee;
  background: transparent !important;
}

.vgt-table.polar-bear thead th .vgt-left-align {
  background: transparent !important;
}

.vgt-wrap.polar-bear .vgt-wrap__footer {
  background: transparent !important;
  margin-top: 15px;
}

.vgt-wrap.polar-bear .vgt-wrap__footer .footer__row-count__label {
  display: none;
}

.vgt-table.polar-bear thead th {
  border: 0;
}

.vgt-wrap {
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%) !important;
  border-radius: 0.42rem !important;
}

.vgt-responsive {
  border-radius: 0.42rem !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f3f6f9;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}
</style>
