const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem('dados_user'));
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveUser = user => {
  window.localStorage.setItem('dados_user', JSON.stringify(user));
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem('dados_user');
};

export default { getToken, saveToken, destroyToken, saveUser };
