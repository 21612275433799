import usuarios from "@/modules/Usuarios/pages/Index";
import usuarioNovo from "@/modules/Usuarios/pages/Create";
import usuarioEditar from "@/modules/Usuarios/pages/Update";

export default [
  {
    path: "/usuarios",
    name: "usuarios",
    component: usuarios
  },
  {
    name: "usuarioNovo",
    path: "/usuario/novo",
    component: usuarioNovo
  },
  {
    name: "usuarioEditar",
    path: "/usuario/:id/editar",
    component: usuarioEditar
  }
];
