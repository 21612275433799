<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Arquivo
        </span>
      </h3>
    </div>
    <Form url="/arquivos/update" :arquivo="arquivo" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ArquivoData from "./ArquivoData";

export default {
  name: "app-arquivos-update",
  data() {
    return {
      arquivo: new ArquivoData({}),
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getArquivo(id) {
      const { data } = await this.$http.post("/arquivos/find", {
        id: id,
      });
      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;
        this.setLoading(true);

        const arquivo = await this.getArquivo(id);

        this.setLoading(false);

        if (!arquivo.id || arquivo == null) {
          this.$router.push({ name: "arquivos" });

          return this.$toast.error("Arquivo não encontrado!");
        }

        this.arquivo = new ArquivoData(arquivo);
      } catch (e) {
        return;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Editando Arquivo",
      },
    ]);
    this.getInit();
  },
};
</script>
