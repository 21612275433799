import ordens from "@/modules/Ordens/pages/Index";
import ordemNovo from "@/modules/Ordens/pages/Create";
import ordemEditar from "@/modules/Ordens/pages/Update";

export default [
  {
    path: "/ordens",
    name: "ordens",
    component: ordens
  },
  {
    name: "ordemNovo",
    path: "/ordem/novo",
    component: ordemNovo
  },
  {
    name: "ordemEditar",
    path: "/ordem/:id/editar",
    component: ordemEditar
  }
];
