<template>
  <TableApp
    titulo="Prestadores"
    acaoBtn="/#/prestador/novo"
    textoBtn="Novo"
    api-url="/pessoas/filterPrestadores"
    :fields="fields"
    classFilterColumn="nome"
    ref="prestadores"
    :perPage="10"
  >
    <template slot="customRow" scope="props">
      <span slot="nome" v-if="props.field == 'nome'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.nome
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="email" v-if="props.field == 'email'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.email
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="telefone" v-if="props.field == 'telefone'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.telefone
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="cidade" v-if="props.field == 'cidade'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.cidade
              }}</span>
            </div>
          </div>
        </span>
      </span>
            <span v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <router-link
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Editar"
                :to="{
                  name: 'prestadorEditar',
                  params: {
                    id: props.rowData.id
                  }
                }"
              >
              <i class="la la-pencil"></i>
              </router-link>
              <button
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Excluir"
              >
                <i class="la la-trash"></i>
              </button>
            </div>
          </span>
        </div>
      </span>
      </template>
  </TableApp>
</template>

<script>
import TableApp from "@/components/Table/Table2";
import { mapGetters } from "vuex";


export default {
  name: "prestadores",
  components: {
    TableApp,
  },
  data() {
    return {
      fields: [
        {
          label: "Prestador",
          field: "nome",
          type: "text",
          sortable: true,
          slot: true,
          width: "20%",
          filterOptions: {
            enabled: true,
            placeholder: "Pesquisar pelo nome"
          }
        },
        {
          label: "E-mail",
          field: "email",
          type: "text",
          sortable: true,
          slot: true,
          width: "20%",
        },
        {
          label: "Telefone",
          field: "telefone",
          type: "text",
          sortable: true,
          slot: true,
          width: "10%",
        },
        {
          label: "Cidade",
          field: "cidade",
          type: "text",
          sortable: true,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
          filterOptions: {
            enabled: false
          }
        }
      ]
    };
  },
  methods: {
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o prestador " + data.nome + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60"
        })
        .then(async result => {
          if (result.isConfirmed) {
            await this.$http.post("/pessoas/delete", {
              id: data.id
            });

            this.$refs.prestadores.refresh();
            this.$toast.success("Prestador excluído!");
          }
        });
    }
  },
    computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
  },
};
</script>
