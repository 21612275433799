<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Cliente
        </span>
      </h3>
    </div>
    <Form url="/pessoas/update" :cliente="cliente" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ClienteData from './ClienteData';

export default {
  name: "app-cliente-update",
  data() {
    return {
      cliente: new ClienteData({})
    };
  },
  components: {
    Form
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getCliente(id) {
      const { data } = await this.$http.post("/pessoas/find", {
        id: id
      });
      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;
        this.setLoading(true);

        const cliente = await this.getCliente(id);

        this.setLoading(false);

        if (!cliente.id || cliente == null) {
          this.$router.push({ name: "clientes" });

          return this.$toast.error("Cliente não encontrado!");
        }

        this.cliente = new ClienteData(cliente);
      } catch (e) {
        return;
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Editando Cliente"
      }
    ]);
    this.getInit();
  }
};
</script>
