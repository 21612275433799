import prestadores from "@/modules/Prestadores/pages/Index";
import prestadorNovo from "@/modules/Prestadores/pages/Create";
import prestadorEditar from "@/modules/Prestadores/pages/Update";

export default [
  {
    path: "/prestadores",
    name: "prestadores",
    component: prestadores
  },
  {
    name: "prestadorNovo",
    path: "/prestador/novo",
    component: prestadorNovo
  },
  {
    name: "prestadorEditar",
    path: "/prestador/:id/editar",
    component: prestadorEditar
  }
];
