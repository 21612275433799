<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none;">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-row>
            <b-col md="2">
              <b-form-group id="placa" label-for="placa" label="Placa">
                <b-form-input
                  v-mask="'AAA-#X##'"
                  v-model="$v.form.placa.$model"
                  :state="validateState('placa')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group id="modelo" label-for="modelo" label="Ano/Modelo">
                <b-form-input
                  placeholder="2021/2021"
                  v-mask="['####/####']"
                  v-model="form.modelo"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="descricao"
                label-for="descricao"
                label="Descrição"
              >
                <b-form-input
                  v-model="$v.form.descricao.$model"
                  :state="validateState('descricao')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Defina o tipo?" label-for="tipo">
                <b-form-select v-model="form.tipo">
                  <b-form-select-option
                    v-for="(t, index) in tipoVeiculos"
                    :value="t.id"
                    :key="'tipo_veiculo' + index"
                  >
                    {{ t.descricao }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="2">
              <b-form-group label="Cor do veículo" label-for="cor">
                <b-form-input
                  id="cor"
                  v-model="form.cor"
                  type="color"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_veiculo_novo"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <button
                v-on:click="
                  () => {
                    $router.push({ name: 'veiculos' });
                  }
                "
                class="btn btn-clean font-weight-bold"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import VeiculoData, { VeiculoDataRequired } from "./VeiculoData";

export default {
  name: "app-veiculo-form",
  props: {
    url: {
      type: String,
    },
    veiculo: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      pessoas: [],
      pessoa: [],
      tipoVeiculos: [
        {
          id: 1,
          descricao: "Cliente",
        },
        {
          id: 2,
          descricao: "Prestador",
        },
      ],
    };
  },
  validations: {
    form: VeiculoDataRequired,
  },
  async mounted() {
    await this.getDataInit();
  },
  watch: {
    veiculo(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new VeiculoData({});
      this.pessoas = await this.getPessoas();

      if (this.veiculo.id) {
        this.form = await this.veiculo;
        this.setIDPessoa(await this.veiculo.pessoa);
      }
    },
    setIDPessoa(pessoa) {
      this.form.id_pessoa = pessoa.id;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_veiculo_novo"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");
        if (this.form.pessoa != null) {
          this.form.id_pessoa = this.form.pessoa.id;
        }

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "veiculos" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
