import arquivos from "@/modules/Arquivos/pages/Index";
import arquivoNovo from "@/modules/Arquivos/pages/Create";
import arquivoEditar from "@/modules/Arquivos/pages/Update";

export default [
  {
    path: "/arquivos",
    name: "arquivos",
    component: arquivos,
  },
  {
    name: "arquivoNovo",
    path: "/arquivos/novo",
    component: arquivoNovo,
  },
  {
    name: "arquivoEditar",
    path: "/arquivos/:id/editar",
    component: arquivoEditar,
  },
];
