<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #f2c98a"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logo_topo.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923"
          >
            Broetto-OS<br />a forma mais rápida para realizar seus atendimentos
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Bem vindo ao Broetto-OS
                </h3>
              </div>
              <b-form-group id="login-email" label="" label-for="login-email">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >E-mail</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="login-email"
                  name="email"
                  ref="email"
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback">
                  E-mail inválido.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="example-input-group-2"
                label=""
                label-for="example-input-2"
              >
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Esqueceu a senha?</a
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="password"
                  id="example-input-2"
                  name="example-input-2"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  Campo senha é obrigatório.
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Entrar
                </button>
              </div>
            </form>
          </div>
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitConta()"
            >
              <!-- <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Cadastre-se
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Informe os dados para criar sua conta
                </p>
              </div> -->

              <b-form-group id="login-email" label="" label-for="login-email">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Nome</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="conta-nome"
                  name="nome"
                  autocomplete="off"
                  placeholder="Seu nome"
                  ref="nome"
                  v-model="$v.conta.nome.$model"
                  :state="validadeStateConta('nome')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="login-email" label="" label-for="login-email">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >E-mail</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="conta-email"
                  name="conta-email"
                  autocomplete="off"
                  ref="conta-email"
                  placeholder="Seu e-mail"
                  v-model="$v.conta.email.$model"
                  :state="validadeStateConta('email')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="example-input-group-2"
                label=""
                label-for="example-input-2"
              >
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="password"
                  id="conta-senha"
                  name="conta-senha"
                  autocomplete="off"
                  v-model="$v.conta.password.$model"
                  :state="validadeStateConta('password')"
                  aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  Campo obrigatório.
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  Aceito com os
                  <a href="#" class="ml-2">termos e condições</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_submit_conta"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  Salvar
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <div class="login-form login-forgot">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              @submit.stop.prevent="onSubmitResetConta()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Esqueceu a senha?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Insira seu e-mail para redefinir sua Senha
                </p>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >E-mail</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="email"
                  name="email"
                  ref="email"
                  v-model="$v.reset.email.$model"
                  :state="validadeStateReset('email')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  Campo obrigatório.
                </b-form-invalid-feedback>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN, REGISTER } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { httpResetLogin } from "../../../configurations/axios";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: ""
      },
      conta: {
        nome: "",
        nome_empresa: "",
        email: "",
        password: ""
      },
      reset: {
        email: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    },
    conta: {
      nome: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    },
    reset: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validadeStateConta(name) {
      const { $dirty, $error } = this.$v.conta[name];
      return $dirty ? !$error : null;
    },
    validadeStateReset(name) {
      const { $dirty, $error } = this.$v.reset[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmitLogin() {
      const submitButton = this.$refs["kt_login_submit"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        const email = this.$v.form.email.$model;
        const password = this.$v.form.password.$model;

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        submitButton.setAttribute("disabled", "");

        await this.$store.dispatch(LOGIN, { email, password });
        this.$toast.success("Logado com sucesso!");

        this.$router.push({ name: "dashboard" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    async onSubmitConta() {
      const submitButton = this.$refs["kt_login_submit_conta"];

      try {
        this.$v.conta.$touch();

        if (this.$v.conta.$anyError) {
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        await this.$store.dispatch(REGISTER, this.conta);

        this.$router.push({ name: "dashboard" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    async onSubmitResetConta() {
      const submitButton = this.$refs["kt_login_forgot_submit"];

      try {
        this.$v.reset.$touch();

        if (this.$v.reset.$anyError) {
          return;
        }

        const email = this.$v.reset.email.$model;
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        await httpResetLogin.post("usuario/reset-password", {
          email: email
        });

        this.$toast.success("Verifique no seu e-mail e siga o procedimento!");
        return submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/data-points.svg"
      );
    }
  },
  mounted() {
    KTUtil.getById("kt_login_signin_form");
    KTUtil.getById("kt_login_signup_form");
    KTUtil.getById("kt_login_forgot_form");
  }
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
