import moment from "moment";

export default {
  methods: {
    async getTiposUsuario() {
      try {
        const { data } = await this.$http.post("/usuarios/tipo");
        return data.data;
      } catch (error) {
        return [];
      }
    },
    async getAtendentes() {
      try {
        const { data } = await this.$http.post("/usuarios/filterAll");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getOperadores() {
      try {
        const { data } = await this.$http.post("/usuarios/filterOperadores");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getTiposCliente() {
      try {
        const { data } = await this.$http.post("/pessoas/tipo");
        return data.data;
      } catch (error) {
        return [];
      }
    },
    async getPrestadores() {
      try {
        const { data } = await this.$http.post("/pessoas/filterAllPrestadores");
        return data;
      } catch (error) {
        return [];
      }
    },
    async consultaCEP(cep) {
      try {
        const { data } = await this.$http.post("/pessoas/consultaCEP", {
          cep
        });
        return data.data;
      } catch (error) {
        return [];
      }
    },
    async getPessoas() {
      try {
        const { data } = await this.$http.post("/pessoas/filterAll");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getCliente() {
      try {
        const { data } = await this.$http.post("/pessoas/filterCliente");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getVeiculosPrestadores() {
      try {
        const { data } = await this.$http.post("/veiculos/veiculosPrestadores");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getVeiculosClientes() {
      try {
        const { data } = await this.$http.post("/veiculos/veiculosClientes");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getMotoristas() {
      try {
        const { data } = await this.$http.post("/usuarios/motoristas");
        return data;
      } catch (error) {
        return [];
      }
    },
    formatDate(value, fmt = "DD/MM/YYYY ") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
    thisDate() {
      return moment().format("YYYY-MM-DD");
    },
    valorReal(valor = 0) {
      return valor.toLocaleString("pt-br", {
        minimumFractionDigits: 2
      });
    },
  }
};
