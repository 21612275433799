<template>
        <div>
        <div class="row m-0">
          <div class="col bg-white px-6 py-8 rounded-xl mr-7 mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
              <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
            </span>
            <a href="#/clientes" class="text-info font-weight-bold font-size-h6">
              Clientes
            </a>
          </div>
          <div class="col bg-white px-6 py-8 rounded-xl mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
              <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
            </span>
            <a href="#/prestadores" class="text-info font-weight-bold font-size-h6 mt-2">
              Prestadores
            </a>
          </div>
        </div>
        <div class="row m-0">
          <div class="col bg-white px-6 py-8 rounded-xl mr-7">
            <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2" style="fill: #696a6c !important">
              <inline-svg src="media/svg/icons/Clothes/Cart.svg" />
            </span>
            <a href="#/veiculos" class="text-info font-weight-bold font-size-h6 mt-2">
              Veículos
            </a>
          </div>
          <div class="col bg-white px-6 py-8 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
              <inline-svg src="media/svg/icons/Files/Folder-plus.svg" />
            </span>
            <a href="#/ordens" class="text-info font-weight-bold font-size-h6 mt-2">
              Ordens (OS)
            </a>
          </div>
        </div>
        </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget-1",
  components: {
  },
  data() {
    return {
      strokeColor: "#2E82DF",
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {

  }
};
</script>
