<template>
  <TableApp
    titulo="Arquivos"
    acaoBtn="/#/arquivos/novo"
    textoBtn="Novo"
    api-url="/arquivos/filter"
    :fields="fields"
    classFilterColumn="nome"
    ref="arquivos"
    :perPage="10"
  >
    <template slot="customRow" scope="props">
      <span slot="nome" v-if="props.field == 'nome'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">{{
                props.rowData.nome
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span slot="tipo" v-if="props.field == 'tipo'">
        <span
          v-if="props.rowData.tipo == 1"
          class="label label-lg label-inline label-light-success"
          >Leilão</span
        >
        <span
          v-else-if="props.rowData.tipo == 2"
          class="label label-lg label-inline label-light-info"
          >Normal</span
        >
      </span>
      <span slot="file" v-if="props.field == 'file'">
        <span class="pl-0 pt-8" v-if="props.rowData.file">
          <div class="d-flex align-items-center">
            <div>
              <a
                :href="
                  'https://broetto.sfo3.digitaloceanspaces.com/arquivos/' +
                  props.rowData.file
                "
                target="_blank"
                class="font-weight-bold d-block"
                >Acessar</a
              >
            </div>
          </div>
        </span>
        <span class="pl-0 pt-8" v-if="!props.rowData.file">
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold d-block">Sem arquivo</span>
            </div>
          </div>
        </span>
      </span>
      <span v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <router-link
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Editar"
                :to="{
                  name: 'arquivoEditar',
                  params: {
                    id: props.rowData.id,
                  },
                }"
              >
                <i class="la la-pencil"></i>
              </router-link>
              <button
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                title="Excluir"
              >
                <i class="la la-trash"></i>
              </button>
            </div>
          </span>
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import TableApp from "@/components/Table/Table2";
import { mapGetters } from "vuex";
// import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import Swal from "sweetalert2";

export default {
  name: "arquivos",
  components: {
    TableApp,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      fields: [
        {
          label: "Arquivo",
          field: "nome",
          type: "text",
          sortable: true,
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: true,
            placeholder: "Arquivo",
          },
        },
        {
          label: "Tipo Arquivo",
          field: "tipo",
          type: "text",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "Arquivo",
          field: "file",
          type: "text",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o arquivo " + data.id + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/arquivos/delete", {
              id: data.id,
            });

            this.$refs.arquivos.refresh();
            this.$toast.success("Arquivo excluída!");
          }
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {},
};
</script>
