import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import { http } from "./configurations/axios";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import VueToast from "vue-toast-notification";
import vueMoment from "vue-moment";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from 'v-mask'

Vue.config.productionTip = false;

import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import 'vue-toast-notification/dist/theme-sugar.css';
import "sweetalert2/dist/sweetalert2.min.css";

const routesNotAuth = ["register", "login", "reset-password"];

router.beforeEach((to, from, next) => {
  if (routesNotAuth.indexOf(to.name) == "-1") {
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  }

  next();

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.prototype.$http = http;
Vue.use(VueSweetalert2);
Vue.use(vueMoment);
Vue.use(VueToast, {
  position: "top-right",
  duration: 8000
});
Vue.use(VueMask);

Vue.prototype.$alert = {
  ...Vue.prototype.$toast,
  error: (message, optional) => {
    Vue.prototype.$toast.error(
      message || "Ocorreu um erro inesperado",
      optional
    );
  }
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
