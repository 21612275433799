import { required } from "vuelidate/lib/validators";

export default class ArquivoData {
  nome = null;

  tipo = null;

  file = null;

  id = null;

  constructor({ nome = null, tipo = null, file = null, id = null }) {
    this.nome = nome;
    this.tipo = tipo;
    this.file = file;
    this.id = id;
  }
}

export const ArquivoDataRequired = {
  nome: {
    required,
  },
};
