<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none;">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-row>
            <b-col md="6">
              <b-form-group label="O novo usuário é?" label-for="tipo">
                <b-form-select
                :state="validateState('tipo')"
                v-model="$v.form.tipo.$model">
                  <b-form-select-option
                    v-for="(t, index) in tipoUsuario"
                    :value="t.id"
                    :key="'tipo_usuario' + index"
                  >
                    {{ t.descricao }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group id="nome" label-for="nome" label="Nome">
                <b-form-input
                  v-model="$v.form.nome.$model"
                  :state="validateState('nome')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="email" label-for="email" label="E-mail">
                <b-form-input
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="password" label-for="email" label="Senha">
                <b-form-input
                  type="password"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_usuario_novo"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <button
                v-on:click="
                  () => {
                    $router.push({ name: 'usuarios' });
                  }
                "
                class="btn btn-clean font-weight-bold"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import UsuarioData, { UsuarioDataRequired } from "./UsuarioData";
import { mask } from "vue-the-mask";

export default {
  name: "app-usuario-form",
  props: {
    url: {
      type: String,
    },
    usuario: {
      type: UsuarioData,
      required: true,
    },
  },
  directives: {
    mask
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      tipoUsuario: [],
    };
  },
  validations: {
    form: UsuarioDataRequired,
  },
  mounted() {
    this.getDataInit();
  },
  watch: {
    usuario(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  methods: {
    async getDataInit() {
      this.form = new UsuarioData({});
      this.tipoUsuario = await this.getTiposUsuario();

      if (this.usuario.id) {
        this.form = await this.usuario;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_usuario_novo"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "usuarios" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
