<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      color="#6993FF"
      :opacity="0.1"
      :width="34"
      :height="34"
      :is-full-page="fullPage"
    >
    </loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      isLoading: true,
      fullPage: true
    };
  },
  components: {
    Loading
  },
  methods: {
    doAjax() {
      this.isLoading = true;
    },
    onCancel() {}
  }
};
</script>
