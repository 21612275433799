<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none;">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Informações da Ordem de Serviço
            </a>
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab1"
              href="#"
              role="tab1"
            >
              Detalhes dos serviços
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab2"
              href="#"
              role="tab2"
            >
              Pagamento
            </a>
          </li> -->
        </ul>
      </div>
      <!-- <b-form class="form" @submit.stop.prevent="onSubmit"> -->
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <div class="card-body">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Qual tipo de OS deseja fazer?"
                  label-for="tipo"
                >
                  <b-form-select v-model="tipoOSSelecionado">
                    <b-form-select-option
                      v-for="(t, index) in tipoOS"
                      :value="t.id"
                      :key="'tipo_situacao' + index"
                    >
                      {{ t.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group id="numero" label-for="numero" label="Número">
                  <b-form-input
                    maxlength="10"
                    type="text"
                    v-model="$v.form.numero.$model"
                    :state="validateState('numero')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="data_sinistro"
                  label-for="data_sinistro"
                  label="Data Atendimento"
                >
                  <b-form-input
                    type="date"
                    v-model="$v.form.data_sinistro.$model"
                    :state="validateState('data_sinistro')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group
                  id="local_sinistro"
                  label-for="local_sinistro"
                  label="Local Atendimento"
                >
                  <b-form-input maxlength="100" v-model="form.local_sinistro">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Veículo Prestador"
                  label-for="id_veiculo_prestador"
                >
                  <v-select
                    label="placa"
                    @input="setIDVeiculoPrestador"
                    v-model="form.veiculo_prestador"
                    :options="veiculosPrestadores"
                  >
                    <template slot="no-options">
                      Informe a placa para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.placa + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.placa + " - " + option.descricao }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <button
                  style="margin-top: 25px"
                  v-b-modal.modal-1
                  class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                  title="Novo"
                >
                  <i class="la la-plus"></i>
                </button>
                <b-modal id="modal-1" title="Novo veículo prestador" size="xl">
                  <b-form
                    class="form"
                    @submit.stop.prevent="onSubmitVeiculoPrestador"
                  >
                    <div class="card-body">
                      <b-row>
                        <b-col md="2">
                          <b-form-group
                            id="placa"
                            label-for="placa"
                            label="Placa"
                          >
                            <b-form-input
                              required
                              v-mask="'AAA-####'"
                              v-model="veiculoPrestador.placa"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group
                            id="modelo"
                            label-for="modelo"
                            label="Ano/Modelo"
                          >
                            <b-form-input
                              placeholder="2021/2021"
                              v-mask="'AAA-#X##'"
                              v-model="veiculoPrestador.modelo"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            id="descricao"
                            label-for="descricao"
                            label="Descrição"
                          >
                            <b-form-input
                              required
                              v-model="veiculoPrestador.descricao"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="2">
                          <b-form-group label="Cor do veículo" label-for="cor">
                            <b-form-input
                              id="cor"
                              v-model="veiculoPrestador.cor"
                              type="color"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <div class="col-lg-12" style="text-align: center">
                          <button
                            type="submit"
                            ref="submit_veiculoPrestador_novo"
                            class="btn btn-primary font-weight-bold"
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </b-modal>
              </b-col>
              <b-col md="10">
                <b-form-group
                  id="descricao_sinistro"
                  label-for="descricao_sinistro"
                  label="Descrição Atendimento"
                >
                  <b-form-input
                    maxlength="100"
                    v-model="form.descricao_sinistro"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group
                  id="solicitante"
                  label-for="solicitante"
                  label="Solicitante"
                >
                  <b-form-input maxlength="100" v-model="form.solicitante">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="telefone_solicitante"
                  label-for="telefone_solicitante"
                  label="Telefone Solicitante"
                >
                  <b-form-input
                    v-mask="['(##) # #### ####']"
                    v-model="form.telefone_solicitante"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group label="Cliente" label-for="id_pessoa">
                  <v-select
                    @input="setCliente"
                    v-model="form.cliente"
                    :options="clientes"
                    :filter-by="myFilter"
                  >
                    <template slot="no-options">
                      Informe o nome para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome + " - " + option.identificacao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome + " - " + option.identificacao }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <button
                  style="margin-top: 25px"
                  v-b-modal.modal-2
                  class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                  title="Novo Cliente"
                >
                  <i class="la la-plus"></i>
                </button>
                <b-modal id="modal-2" title="Novo cliente" size="xl">
                  <b-form class="form" @submit.stop.prevent="onSubmitCliente">
                    <div class="card-body">
                      <b-row>
                        <b-col md="4">
                          <b-form-group id="nome" label-for="nome" label="Nome">
                            <b-form-input required v-model="pessoa.nome">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            id="email"
                            label-for="email"
                            label="E-mail"
                          >
                            <b-form-input required v-model="pessoa.email">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group
                            id="telefone"
                            label="Telefone"
                            label-for="example-input-0"
                          >
                            <b-form-input
                              required
                              v-mask="['(##) #### ####', '(##) # #### ####']"
                              v-model="pessoa.telefone"
                              aria-describedby="input-0-live-feedback"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group
                            id="identificacao"
                            label="CPF/CNPJ"
                            label-for="identificacao"
                          >
                            <b-form-input
                              required
                              id="identificacao"
                              name="identificacao"
                              v-model="pessoa.identificacao"
                              v-mask="['###.###.###-##', '##.###.###/####-##']"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group id="rg" label="RG" label-for="RG">
                            <b-form-input
                              id="rg"
                              name="rg"
                              v-model="pessoa.rg"
                              v-mask="'#.###-###'"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label="CEP" label-for="cep">
                            <b-form-input
                              ref="cep"
                              id="cep"
                              name="cep"
                              required
                              v-model="pessoa.cep"
                              v-mask="['#####-###']"
                              @change="consultaCep"
                              aria-describedby="form.cep"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col md="3">
                          <b-form-group label="Número" label-for="numero">
                            <b-form-input
                              id="numero"
                              name="numero"
                              v-model="pessoa.numero"
                              aria-describedby="pessoa.numero"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="2">
                          <b-form-group label="Bairro" label-for="bairro">
                            <b-form-input
                              id="bairro"
                              name="bairro"
                              v-model="pessoa.bairro"
                              aria-describedby="pessoa.bairro"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group label="Logradouro" label-for="endereco">
                            <b-form-input
                              id="endereco"
                              name="endereco"
                              v-model="pessoa.endereco"
                              aria-describedby="endereco"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group label="Cidade" label-for="cidade">
                            <b-form-input
                              id="cidade"
                              name="cidade"
                              v-model="pessoa.cidade"
                              aria-describedby="form.cidade"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="1">
                          <b-form-group label="UF" label-for="uf">
                            <b-form-input
                              id="uf"
                              name="uf"
                              v-model="pessoa.uf"
                              aria-describedby="pessoa.uf"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <div class="col-lg-12" style="text-align: center">
                          <button
                            type="submit"
                            ref="submit_cliente_novo"
                            class="btn btn-primary font-weight-bold"
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </b-modal>
              </b-col>
              <b-col md="4" v-if="tipoOSSelecionado == 1">
                <b-form-group
                  label="Veículo Cliente"
                  label-for="id_veiculo_cliente"
                >
                  <v-select
                    label="placa"
                    @input="setIDVeiculoCliente"
                    v-model="form.veiculo_cliente"
                    :options="veiculosClientes"
                  >
                    <template slot="no-options">
                      Informe a placa para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.placa + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.placa + " - " + option.descricao }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="1" v-if="tipoOSSelecionado == 1">
                <button
                  style="margin-top: 25px"
                  v-b-modal.modal-3
                  class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                  title="Novo Veículo"
                >
                  <i class="la la-plus"></i>
                </button>
                <b-modal id="modal-3" title="Novo Veículo Cliente" size="xl">
                  <b-form
                    class="form"
                    @submit.stop.prevent="onSubmitViculoCliente"
                  >
                    <div class="card-body">
                      <b-row>
                        <b-col md="2">
                          <b-form-group
                            id="placa"
                            label-for="placa"
                            label="Placa"
                          >
                            <b-form-input
                              required
                              v-mask="'AAA-#X##'"
                              v-model="veiculoCliente.placa"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group
                            id="modelo"
                            label-for="modelo"
                            label="Ano/Modelo"
                          >
                            <b-form-input
                              placeholder="2021/2021"
                              v-mask="['####/####']"
                              v-model="veiculoCliente.modelo"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            id="descricao"
                            label-for="descricao"
                            label="Descrição"
                          >
                            <b-form-input
                              required
                              v-model="veiculoCliente.descricao"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="2">
                          <b-form-group label="Cor do veículo" label-for="cor">
                            <b-form-input
                              id="cor"
                              v-model="veiculoCliente.cor"
                              type="color"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <div class="col-lg-12" style="text-align: center">
                          <button
                            type="submit"
                            ref="submit_veiculoCliente_novo"
                            class="btn btn-primary font-weight-bold"
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </b-modal>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Qual foi o operador?"
                  label-for="id_motorista"
                >
                  <v-select
                    label="nome"
                    @input="setMotorista"
                    v-model="form.motorista"
                    :options="motoristas"
                  >
                    <template slot="no-options">
                      Informe a placa para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  id="atendimento"
                  label-for="atendimento"
                  label="Descrição do Atendimento"
                >
                  <b-form-textarea
                    type="text"
                    placeholder="Descrever o seu atendimento......."
                    v-model="form.atendimento"
                    rows="3"
                    max-rows="6"
                  >
                  </b-form-textarea>
                </b-form-group>
                <b-row v-if="tipoOSSelecionado == 2">
                  <b-col md="2">
                    <b-form-group
                      label="Período do serviço?"
                      label-for="periodo"
                    >
                      <b-form-select v-model="periodo">
                        <b-form-select-option
                          v-for="(p, index) in periodos"
                          :value="p.descricao"
                          :key="'tipo_periodo' + index"
                        >
                          {{ p.descricao }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="horaInicio"
                      label-for="horaInicio"
                      label="Hora de Início"
                    >
                      <b-form-input type="time" v-model="horaInicio">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="horaFinal"
                      label-for="horaFinal"
                      label="Hora Final"
                    >
                      <b-form-input type="time" v-model="horaFinal">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="2">
                    <b-form-group
                      id="valorServico"
                      label-for="valorServico"
                      label="Valor"
                    >
                      <money
                        class="form-control"
                        v-model="valorServico"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col> -->
                  <b-col md="2">
                    <b-form-group
                      id="totalHoras"
                      label-for="totalHoras"
                      label="Total de Horas"
                    >
                      <b-form-input type="time" v-model="totalHoras">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="valor_total_horas"
                      label-for="valor_total_horas"
                      label="R$ Valor total de horas"
                    >
                      <money
                        class="form-control"
                        v-model="form.valor_total_horas"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col>
                  <b-col md="1">
                    <button
                      style="margin-top: 20px"
                      v-on:click="addServico()"
                      class="btn btn-primary btn-small font-weight-bold"
                    >
                      Adicionar
                    </button>
                  </b-col>
                </b-row>
                <b-row v-if="tipoOSSelecionado == 2">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Período</th>
                        <th scope="col">Hora Início</th>
                        <th scope="col">Hora Final</th>
                        <!-- <th scope="col">Valor</th> -->
                        <th scope="col">Ações</th>
                      </tr>
                    </thead>
                    <tbody v-for="(item, index) in servicos" :key="index">
                      <tr v-show="index != 'totalHoras'">
                        <th>{{ index }}</th>
                        <th>{{ item.periodo }}</th>
                        <td>{{ item.horaInicio }}</td>
                        <td>{{ item.horaFinal }}</td>
                        <!-- <td>{{ valorReal(item.valorServico) }}</td> -->
                        <td>
                          <button
                            @click="excluir(index)"
                            class="btn btn-sm btn-success btn-hover-info btn-icon mr-2"
                            title="Excluir"
                          >
                            <i class="la la-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td><strong>Total de Horas:</strong></td>
                        <td>
                          <strong>{{ totalHoras }}</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </b-row>
                <b-row v-if="tipoOSSelecionado == 1">
                  <b-col md="2">
                    <b-form-group
                      id="kmSaida"
                      label-for="kmSaida"
                      label="KM Saída"
                    >
                      <money
                        class="form-control"
                        v-model="kmSaida"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="kmChegada"
                      label-for="kmChegada"
                      label="KM Chegada"
                    >
                      <money
                        class="form-control"
                        v-model="kmChegada"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="totalKm"
                      label-for="totalKm"
                      label="Total KM"
                    >
                      <money
                        class="form-control"
                        v-model="totalKm"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="tipoOSSelecionado == 1">
                  <b-col md="4">
                    <b-form-group id="origem" label-for="origem" label="Origem">
                      <b-form-input maxlength="100" v-model="origem">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      id="destino"
                      label-for="destino"
                      label="Destino"
                    >
                      <b-form-input maxlength="100" v-model="destino">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="2">
                    <b-form-group
                      id="data_pagamento"
                      label-for="data_sinistro"
                      label="Data Pagamento"
                    >
                      <b-form-input type="date" v-model="form.data_pagamento">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="valor_pago"
                      label-for="valor_pago"
                      label="Valor Pago"
                    >
                      <money
                        class="form-control"
                        v-model="form.valor_pago"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="2">
                <b-form-group label="Defina a situação?" label-for="status">
                  <b-form-select v-model="form.status">
                    <b-form-select-option
                      v-for="(t, index) in tipoSituacao"
                      :value="t.id"
                      :key="'tipo_situacao' + index"
                    >
                      {{ t.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-tab>

        <!-- <b-tab>
          <div class="card-body"></div>
        </b-tab>

        <b-tab>
          <div class="card-body"></div>
        </b-tab> -->
      </b-tabs>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button @click="gravar()" class="btn btn-primary font-weight-bold">
              Salvar
            </button>
            <button
              v-on:click="
                () => {
                  $router.push({ name: 'ordens' });
                }
              "
              class="btn btn-clean font-weight-bold"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
      <!-- </b-form> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import OrdemData, { OrdemDataRequired } from "./OrdemData";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { mask } from "vue-the-mask";

export default {
  name: "app-ordem-form",
  props: {
    url: {
      type: String,
    },
    ordem: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    "v-select": VueSelect,
    Money,
  },
  directives: {
    mask,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tipoOS: [
        {
          id: 1,
          descricao: "Guincho",
        },
        {
          id: 2,
          descricao: "Guindaste",
        },
      ],
      tipoOSSelecionado: 2,
      tabIndex: 0,
      form: {},
      clientes: [],
      veiculosPrestadores: [],
      veiculosClientes: [],
      motoristas: [],
      servicos: [],
      periodo: null,
      horaInicio: null,
      horaFinal: null,
      valorServico: null,
      kmSaida: null,
      kmChegada: null,
      destino: null,
      origem: null,
      quilometragem: [],
      tipoSituacao: [
        {
          id: 1,
          descricao: "Aberta",
        },
        {
          id: 2,
          descricao: "Reprovado",
        },
        {
          id: 3,
          descricao: "Aprovado",
        },
      ],
      periodos: [
        {
          id: 1,
          descricao: "Manhã",
        },
        {
          id: 2,
          descricao: "Tarde",
        },
      ],
      parcelamento: [
        {
          id: 1,
          descricao: "1x",
        },
        {
          id: 2,
          descricao: "2x",
        },
        {
          id: 3,
          descricao: "3x",
        },
        {
          id: 4,
          descricao: "4x",
        },
        {
          id: 5,
          descricao: "5x",
        },
        {
          id: 6,
          descricao: "6x",
        },
        {
          id: 7,
          descricao: "7x",
        },
        {
          id: 8,
          descricao: "8x",
        },
        {
          id: 9,
          descricao: "9x",
        },
        {
          id: 10,
          descricao: "10x",
        },
      ],
      configMoney: settingMoney,
      pessoas: [],
      veiculoPrestador: {},
      veiculoCliente: {},
      pessoa: {},
      totalHoras: "00:00",
      numeroOld: null,
    };
  },
  validations: {
    form: OrdemDataRequired,
  },
  async mounted() {
    await this.getDataInit();
  },
  watch: {
    ordem(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new OrdemData({});
      this.clientes = await this.getCliente();
      this.veiculosPrestadores = await this.getVeiculosPrestadores();
      this.veiculosClientes = await this.getVeiculosClientes();
      this.motoristas = await this.getMotoristas();
      this.pessoas = await this.getPessoas();

      if (this.ordem.id) {
        this.form = await this.ordem;
        this.numeroOld = await this.ordem.numero;
        this.tipoOSSelecionado = await this.ordem.tipo_os;
        this.servicos = await this.ordem.servicos;
        this.kmSaida = (await this.ordem.quilometragem[0].kmSaida) ?? null;
        this.totalHoras = (await this.ordem.total_horas) ?? "00:00";
        this.kmChegada = (await this.ordem.quilometragem[0].kmChegada) ?? null;
        this.totalKm = (await this.ordem.quilometragem[0].totalKm) ?? null;
        this.origem = (await this.ordem.quilometragem[0].origem) ?? null;
        this.destino = (await this.ordem.quilometragem[0].destino) ?? null;
        this.setIDVeiculoPrestador(await this.ordem.veiculo_prestador);
        this.setIDVeiculoCliente(await this.ordem.veiculo_cliente);
        this.setCliente(await this.ordem.cliente);
        this.setMotorista(await this.ordem.motorista);
      }
    },
    setIDVeiculoPrestador(veiculo) {
      this.form.id_veiculo_prestador = veiculo.id;
    },
    setIDVeiculoCliente(veiculo) {
      this.form.id_veiculo_cliente = veiculo.id;
    },
    setCliente(cliente) {
      this.form.id_pessoa = cliente.id;
    },
    setMotorista(motorista) {
      this.form.id_motorista = motorista.id;
    },
    setIDPessoa(pessoa) {
      this.veiculoPrestador.id_pessoa = pessoa.id;
    },
    setIDPessoaNovoVeiculo(pessoa) {
      this.veiculoCliente.id_pessoa = pessoa.id;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    myFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return option.nome.toLowerCase().indexOf(temp) > -1 || 
      option.identificacao.toLowerCase().indexOf(temp) > -1
    },
    async gravar() {
      // const submitButton = true;

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        // submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        // submitButton.setAttribute("disabled", "");
        if (!this.form.motorista) {
          this.$toast.warning("Selecione um motorista!");
          return;
        }
        if (!this.form.veiculo_prestador) {
          this.$toast.warning("Selecione um veículo para o prestador!");
          return;
        }
        if (!this.form.veiculo_cliente && this.tipoOSSelecionado === 1) {
          this.$toast.warning("Selecione um veículo para o cliente!");
          return;
        }
        if (!this.form.cliente) {
          this.$toast.warning("Selecione um cliente!");
          return;
        }

        if (this.form.veiculo_prestador != null) {
          this.form.id_veiculo_prestador = this.form.veiculo_prestador.id;
        }
        if (this.form.veiculo_cliente != null) {
          this.form.id_veiculo_cliente = this.form.veiculo_cliente.id;
        }
        if (this.form.cliente != null) {
          this.form.id_pessoa = this.form.cliente.id;
        }
        if (this.form.motorista != null) {
          this.form.id_motorista = this.form.motorista.id;
        }
        if (this.servicos != null) {
          this.form.servicos = this.servicos;
          this.form.totalHoras = this.totalHoras;
        }

        var percurso = {};

        if (this.kmSaida != null) {
          percurso.kmSaida = this.kmSaida;
        }
        if (this.kmChegada != null) {
          percurso.kmChegada = this.kmChegada;
        }
        if (this.totalKm != null) {
          percurso.totalKm = this.totalKm;
        }
        if (this.origem != null) {
          percurso.origem = this.origem;
        }
        if (this.destino != null) {
          percurso.destino = this.destino;
        }
        this.quilometragem.push(percurso);

        if (this.quilometragem != null) {
          this.form.quilometragem = this.quilometragem;
        }

        if (this.tipoOSSelecionado != null) {
          this.form.tipo_os = this.tipoOSSelecionado;
        }

        const user = await JSON.parse(
          window.localStorage.getItem("dados_user")
        );
        this.form.id_atendente = user.id;
        this.form.numeroOld = this.numeroOld;
        const ordem = await this.$http.post("ordens/check-number", this.form);

        if (ordem["data"]["status"]) {
          this.$toast.warning("Esse número de Ordem já foi utilizado!");
          return;
        }

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "ordens" });
      } catch (error) {
        this.$toast.warning(error);
      }
    },

    async addServico() {
      if (!this.horaInicio) {
        this.$toast.warning("Informe a hora inicial!");
        return;
      }

      if (!this.horaFinal) {
        this.$toast.warning("Informe a hora final!");
        return;
      }

      var servico = {};

      servico.horaInicio = this.horaInicio;
      servico.horaFinal = this.horaFinal;
      servico.periodo = this.periodo;
      servico.valorServico = this.valorServico;
      this.servicos.push(servico);
      const data = await this.$http.post("ordens/calculaHora", this.servicos);
      this.totalHoras = data.data.totalHoras;
      this.limpaServico();
      this.$toast.success("Serviço adicionado com sucesso!");
    },

    limpaServico() {
      this.horaInicio = null;
      this.horaFinal = null;
      this.periodo = null;
      this.valorServico = 0.0;
    },
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir serviço prestado ?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.servicos.splice(data, 1);
            const total = await this.$http.post(
              "ordens/calculaHora",
              this.servicos
            );
            this.totalHoras = total.data.totalHoras;
            this.$toast.success("Serviço excluído!");
          }
        });
    },
    novoVeiculoPrestador() {
      this.modalVeiculoPrestador = true;
    },
    async onSubmitVeiculoPrestador() {
      const submitButton = this.$refs["submit_veiculoPrestador_novo"];
      try {
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        this.veiculoPrestador.tipo = 2;

        await this.$http.post("/veiculos/create", this.veiculoPrestador);

        this.$toast.success("Veículo cadastrado com sucesso!");

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");

        this.veiculosPrestadores = await this.getVeiculosPrestadores();
        this.veiculoPrestador = {};
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    async onSubmitCliente() {
      const submitButton = this.$refs["submit_cliente_novo"];

      try {
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");
        this.pessoa.id_tipo = 1;
        await this.$http.post("/pessoas/create", this.pessoa);

        this.$toast.success("Cliente cadastrado com sucesso!");

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");

        this.clientes = await this.getCliente();
        this.pessoa = {};
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    async onSubmitViculoCliente() {
      const submitButton = this.$refs["submit_veiculoCliente_novo"];

      try {
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        this.veiculoCliente.tipo = 1;

        const veiculo = await this.$http.post(
          "/veiculos/create",
          this.veiculoCliente
        );

        this.setIDVeiculoCliente(veiculo.data);
        this.form.veiculo_cliente = veiculo.data;
        this.$toast.success("Veículo cadastrado com sucesso!");

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");

        this.veiculosClientes = await this.getVeiculosClientes();
        this.veiculoCliente = {};
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    async consultaCep() {
      this.setLoading(true);
      const cep = await this.consultaCEP(this.pessoa.cep);
      this.pessoa.endereco = cep.data.street;
      this.pessoa.bairro = cep.data.district;
      this.pessoa.cidade = cep.data.city;
      this.pessoa.uf = cep.data.uf;
      this.setLoading(false);
    },
  },
  computed: {
    totalKm() {
      return this.kmChegada - this.kmSaida;
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
