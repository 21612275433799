<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Veículo
        </span>
      </h3>
    </div>
    <Form url="/veiculos/update" :veiculo="veiculo"/>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "app-veiculo-update",
  data() {
    return {
      veiculo: {},
    };
  },
  components: {
    Form
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getVeiculo(id) {
      const { data } = await this.$http.post("/veiculos/find", {
        id: id
      });
      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;
        this.setLoading(true);

        const veiculo = await this.getVeiculo(id);

        this.setLoading(false);

        if (!veiculo.id || veiculo == null) {
          this.$router.push({ name: "veiculos" });

          return this.$toast.error("Veículo não encontrado!");
        }

        this.veiculo = await veiculo;
      } catch (e) {
        return;
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Editando Veículo"
      }
    ]);
    this.getInit();
  }
};
</script>
